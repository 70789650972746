<template>
  <specialty-profile-component />
</template>

<script>
import SpecialtyProfileComponent from "@/components/medical-services/SpecialtyProfileComponent";

export default {
  name: "SpecialtyProfile",
  title: "Specialty Profile | Baja California Health Tourism",
  components: { SpecialtyProfileComponent },
};
</script>

<style scoped></style>
